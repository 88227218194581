import { PROCESS_BASE_URL } from '@/config/endpoints';

export const GET_LOGIN_UUID = `${PROCESS_BASE_URL}/api/v1/login/getUuid`;

export const GET_SCAN = `${PROCESS_BASE_URL}/api/v1/login/getScan`;

export const CHECK_LOGIN = `${PROCESS_BASE_URL}/api/v1/login/login`;

export const GET_SERVER_KEY = `${PROCESS_BASE_URL}/api/v1/init/getPublicKey`;

export const GET_SESSION_LIST = `${PROCESS_BASE_URL}/api/v1/init/getTalkInfo`;

export const LOGOUT = `${PROCESS_BASE_URL}/api/v1/login/logout`;

export const GET_FRIENDS_AND_GROUP = `${PROCESS_BASE_URL}/api/v1/getList`;

export const GET_GROUP_MEMBERS_INFO = `${PROCESS_BASE_URL}/api/v1/group/getGroupMembersInfo`;

export const GEN_RSA_KEY = `${PROCESS_BASE_URL}/api/v1/init/generateKey`;

export const getFSAuth_url = `${PROCESS_BASE_URL}/api/v1/init/getFSAuth`;

export const createGroup_url = `${PROCESS_BASE_URL}/api/v1/group/createGroup`;

export const searchFriend_url = `${PROCESS_BASE_URL}/api/v1/friend/searchFriend`;

export const addFriendRequest_url = `${PROCESS_BASE_URL}/api/v1/friend/addFriendRequestHandle`;

export const getFriendAcceptStatus_url = `${PROCESS_BASE_URL}/api/v1/friend/getFriendAcceptStatus`;

export const getAddFriendResponseHandle_url = `${PROCESS_BASE_URL}/api/v1/friend/addFriendResponseHandle`;

export const getGroupMembersInfo_url = `${PROCESS_BASE_URL}/api/v1/group/getGroupMembersInfo`;

