export const MessageType = {
  Text: 200,
  Image: 201,
  Audio: 202,
  Video: 203,
  Location: 204,
  Namecard: 205,
  system: 207,  //系统消息
  Emj: 209,
  File: 210,
  Sysnoti: 211,
  Redpack: 214,
  BurnText: 300,
  BurnImage: 301,
  BurnAudio: 302,
  BurnVideo: 303,
};

export const MessageStatus = {
  Sending: 0,
  SendError: 1,
  Sent: 2,
  BeenRead: 3,
};

export const MessageChatType = {
  Singel: 100,
  Group: 101,
  Official: 103,
}