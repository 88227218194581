import { v1 as uuidv1 } from 'uuid';
import isObjectLike from 'lodash/isObjectLike';
import store from '@/store';
import { toBase64, delay, md5, aes256cbcDecrypt } from '@/utils/utils';
import { syncServerKey, genRsaKey } from '@/api/api';
import { logerror } from './logger';

let deviceid = localStorage.getItem('__d');

if (!deviceid) {
  deviceid = toBase64(uuidv1());
  localStorage.setItem('__d', deviceid);
}

store.commit('user/initDeviceid', deviceid);

(async () => {
  let _keypair = localStorage.getItem('__KP');

  async function setupKeypair() {
    for (let i = 0; i < 3; i++) {
      try {
        const res = await genRsaKey();
        if (res && res.code === 200) {
          let key = aes256cbcDecrypt(res.data, md5(deviceid));
          if (key) {
            key = JSON.parse(key);
            _keypair = { public: key.pub_key_pkcs8, private: key.prv_key };
            break;
          }
        }
      } catch (e) {
        logerror(e);
      }
    }
    if (_keypair && _keypair.public && _keypair.private) {
      localStorage.setItem('__KP', JSON.stringify(_keypair));
    }
  }

  if (!_keypair) {
    await setupKeypair();
  } else {
    try {
      _keypair = JSON.parse(_keypair);
    } catch { }
    if (!isObjectLike(_keypair) || !_keypair.public || !_keypair.private) {
      await setupKeypair();
    }
  }

  if (_keypair && _keypair.public && _keypair.private) {
    store.commit('user/initKeypair', _keypair);
  }
})()

async function fetchServerKey(v) {
  let done = false;
  try {
    const res = await syncServerKey(v);
    if (res && res.code === 200) {
      const data = res.data;
      localStorage.setItem('__S_K', JSON.stringify(data));
      store.commit('user/setServerKey', data);
      done = true;
    }
  } catch { }
  if (done) return;
  await delay(1000);
  fetchServerKey(v);
}

let serverKey = localStorage.getItem('__S_K');
let keySyncing = false;

if (!serverKey) {
  keySyncing = true;
  fetchServerKey(store.state.user.serverKeyVersion);
} else {
  try {
    serverKey = JSON.parse(serverKey);
  } catch { }
  if (
    !isObjectLike(serverKey) ||
    !serverKey.pubKey ||
    typeof serverKey.version === 'undefined'
  ) {
    keySyncing = true;
    fetchServerKey(store.state.user.serverKeyVersion);
  }
}

if (!keySyncing) {
  store.commit('user/setServerKey', serverKey);
}






