export const PROCESS_BASE_URL = process.env.VUE_APP_PROCESS_API_URL;

export const FILE_ENDPOINT = process.env.VUE_APP_FILES_ENDPOINT;

export const file_url = process.env.VUE_APP_FILES_URL;

export const WS_ENDPOINT = process.env.VUE_APP_WS_ENDPOINT;

export const COMPLETE_FILE_URI = uri => {
  if (!uri) return '';
  // if (uri.startsWith('http://') || uri.startsWith('https://')) {
  //   return uri;
  // }

  if (uri.startsWith('http://') || uri.startsWith('https://')) {
    const url = new URL(uri); 
    return FILE_ENDPOINT + url.pathname;
  }

  return FILE_ENDPOINT + uri;
};