import { DATA_TYPE } from "jsstore";

export default {
  name: 'Conversation',
  columns: {
    id: {
      primaryKey: true,
      autoIncrement: true
    },
    con_id: {
      notNull: true,
      dataType: DATA_TYPE.String,
      unique: true,
    },
    con_name: {
      notNull: true,
      dataType: DATA_TYPE.String
    },
    last_msg: {
      dataType: DATA_TYPE.String,
      default: ''
    },
    last_ts: {
      notNull: true,
      dataType: DATA_TYPE.Number
    },
    avatar: {
      dataType: DATA_TYPE.String,
      default: ''
    },
    msg_badge: {
      dataType: DATA_TYPE.Number,
      default: 0
    },
    chat_type: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
    is_top: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
  }
}