import { connection } from "./jsstore_con";
// import { DATA_TYPE } from "jsstore";
import conversation from "./schemas/conversation";
import message from "./schemas/message";
import contacts from './schemas/contacts';
import group from './schemas/group';

const getDatabase = () => {
  const dataBase = {
    name: `hichat`,
    tables: [
      conversation,
      message,
      contacts,
      group
    ]
  };
  return dataBase;
};

export const initJsStore = async () => {
  const dataBase = getDatabase();
  return await connection.initDb(dataBase);
};