module.exports = {//例子 this.$i18n.t('main.submit_audit')  {{$t('main.status_name')}}

  main: {
    'text1': 'Welcome to HiChat',
    'text2': 'Say "hello" to a different messaging experience.',
    'text3': 'An unexpected focus on privacy, combined with all of the features you expect.',
    'text4': 'Scan to login',
    'text5': 'The web page needs to be used with your mobile phone login',
    'text6': 'Are you sure to log out ?',
    'text7': 'Cancel',
    'text8': 'Confirm',
    'text9': 'Please enter key words',
    'text10': 'Type message here...',
    'text11': 'Remarks',
    'text12': 'Source',
    'text13': 'From group chat',
    'text14': 'Send',
    'text15': 'Contacts',
    'text16': 'Login...',
    'text17': 'Scan success',
    'text18': 'Confirm login with mobile',
    'text20': 'The browser does not support playing this file',
    'text21': 'Current version does not support this message',
    'text22': '',
    'text23': '',
    'text24': '',
    'text25': '',
    'text26': '',
    'text27': '',
    'text28': '',
    'text29': '',
    'text30': '',
    'text31': '',
  },
  message: {
    consImageShowName: '[image]',
    consVideoShowName: '[video]',
    consAudioShowName: '[audio]',
    consFileShowName: '[file]',
    consBigEmojiShowName: '[sticker]',
    consNotSupportShowName: '[not supported]',
    unknownUser: '[Unknown user]',
  },
  contacts: {
    starFriends: 'Star',
  },
}
