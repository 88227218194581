import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import i18n from './i18n';

import '@/utils/directive';
import '@/utils/setupDevice';

Vue.prototype.bus = new Vue();
Vue.config.productionTip = false;
Vue.use(ElementUI);

import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')