import { Connection } from "jsstore";

const getWorkerPath = () => {
  // return dev build when env is development
  if (process.env.NODE_ENV === 'development') {
    // return require("file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.js");
    return 'jsstore/jsstore.worker.js';
  }
  else { // return prod build when env is production
    // return require("file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.min.js");
    return 'jsstore/jsstore.worker.min.js';
  }
};

// const workerPath = getWorkerPath().default;
const workerPath = getWorkerPath();
export const connection = new Connection(new Worker(workerPath));

connection.on('error', (err) => { console.error('catched error: ' + err); });

connection.logStatus = process.env.NODE_ENV === 'development';