import Global from '../global'
import { initJsStore } from '@/service/idb_service'
import { loginfo, logerror } from '@/utils/logger';

export default async function () {
  try {
    const isDbCreated = await initJsStore();
    if (isDbCreated) {
      loginfo("db created");
      // prefill database
    } else {
      loginfo("db opened");
    }
  } catch (err) {
    logerror(err);
    alert(ex.message);
    Global.isIndexedDbSupported = false;
  }
}