import { DATA_TYPE } from "jsstore";

export default {
  name: 'Message',
  columns: {
    id: {
      primaryKey: true,
      autoIncrement: true
    },
    s_info: {
      dataType: DATA_TYPE.Object,
      default: {},
    },
    s_uid: {
      notNull: true,
      dataType: DATA_TYPE.String
    },
    r_uid: {
      notNull: true,
      dataType: DATA_TYPE.String
    },
    con_id: {
      notNull: true,
      dataType: DATA_TYPE.String
    },
    body: {
      notNull: true,
      dataType: DATA_TYPE.Object,
    },
    s_msgId: {
      notNull: true,
      dataType: DATA_TYPE.String,
      unique: true,
    },
    c_msgId: {
      notNull: true,
      dataType: DATA_TYPE.String
    },
    c_type: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
    m_type: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
    s_time: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
    is_read: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
    m_status: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
    meta: {
      dataType: DATA_TYPE.Object,
      default: {},
    },
    is_mine: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
    v: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
  }
}