module.exports = {//例子 this.$i18n.t('main.submit_audit')  {{$t('main.status_name')}}

  main: {
    'text1':                             'Welcome to HiChat',
    'text2':                             'Say "hello" to a different messaging experience.',
    'text3':                             'An unexpected focus on privacy, combined with all of the features you expect.',
    'text4':                             'Scan to login',
    'text5':                             'The web page needs to be used with your mobile phone login',
    'text6':                             'Are you sure to log out ?',
    'text7':                             'Cancel',
    'text8':                             'Confirm',
    'text9':                             'Please enter key words',
    'text10':                            'Type message here...',
    'text11':                            'Remarks',
    'text12':                            'Source',
    'text13':                            'From group chat',
    'text14':                            'Send',
    'text15':                            'Contacts',
    'text16':                            'Login...',
    'text17':                            'Scan success',
    'text18':                            'Confirm login with mobile',
    'text20':                            'The browser does not support playing this file',
    'text21':                            'Current version does not support this message',
    'group_creation_error':              'Group chat creation failed',
    'successfully_added':                'Successfully added',
    'consent':                           'Approved',
    'refuse':                            'Refuse',
    'not_find':                          'Could not find the file, please try again',
    'MD5_failed':                        'File MD5 calculation failed, please try again',
    'least_choice':                      'Please select at least two people',
    'person':                            'people',
    'Group_announcement':                'Group Announcement',
    'Group_member':                      'Group Member',
    'New_friend':                        'New Friend',
    'group_chat':                        'Group Chat',
    'cancel':                            'Cancel',
    'add_friends':                       "Add Friend",
    'creation':                          'Create',
    'Start_group_chat':                  'Launch group chat',
    'unselected':                        'No contact selected',
    'selected':                          'Selected',
    'Contact':                           'contacts',
    'View':                               'View',
    "enter_Hichat":                       "Please enter the Hichat number",

    
  },
  message: {
    consImageShowName: '[image]',
    consVideoShowName: '[video]',
    consAudioShowName: '[audio]',
    consFileShowName: '[file]',
    consBigEmojiShowName: '[sticker]',
    consNotSupportShowName: '[not supported]',
    unknownUser: '[Unknown user]',
  },
  contacts: {
    starFriends: 'Star',
  },
}
