import { DATA_TYPE } from "jsstore";

export default {
  name: 'Contacts',
  columns: {
    id: {
      primaryKey: true,
      autoIncrement: true
    },
    userId: {
      notNull: true,
      dataType: DATA_TYPE.String
    },
    hiChatId: {
      notNull: true,
      dataType: DATA_TYPE.String
    },
    nickName: {
      notNull: true,
      dataType: DATA_TYPE.String
    },
    specialNickName: {
      dataType: DATA_TYPE.String,
      default: ''
    },
    icon: {
      dataType: DATA_TYPE.String,
      default: ''
    },
    isReceiveMsg: {
      notNull: true,
      dataType: DATA_TYPE.Boolean,
    },
    talkType: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
    isStar: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
    isTop: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
    status: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
    isVIP: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
    model: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
    recommendationMyCard: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
    tag: {
      dataType: DATA_TYPE.Array,
      default: [],
    },
    description: {
      dataType: DATA_TYPE.String,
      default: ''
    },
    nickNameFirstLetter: {
      dataType: DATA_TYPE.String,
      default: ''
    },
  }
}