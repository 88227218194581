import CryptoJS from 'crypto-js';
import JSEncrypt from "jsencrypt";
import { encode } from 'js-base64';
import BMF from "browser-md5-file";

export const md5 = (data) => { return CryptoJS.MD5(data).toString(); };

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export const toBase64 = data => encode(data);

export const sha256 = data => CryptoJS.SHA256(data).toString();

export const getAllRandomString = len => {
  let _len = len || 32;
  let $chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let maxPos = $chars.length;
  let pwd = '';
  for (let i = 0; i < _len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
};

export const aes256cbcEncrypt = (content, key, iv = '') => {
  iv = CryptoJS.enc.Utf8.parse(iv);
  key = CryptoJS.enc.Utf8.parse(key);
  return CryptoJS.AES.encrypt(content, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
};

export const aes256cbcDecrypt = (data, key, iv = '') => {
  iv = CryptoJS.enc.Utf8.parse(iv);
  key = CryptoJS.enc.Utf8.parse(key);
  return CryptoJS.AES.decrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8);
};

export const rsaPubKeyEncrypt = (content, pubKey) => {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(pubKey);
  return encryptor.encrypt(content);
};

export const rsaPrvKeyDecrypt = (data, prvKey) => {
  const decryptor = new JSEncrypt();
  decryptor.setPrivateKey(prvKey);
  return decryptor.decrypt(data);
};

export const base64ConvertToFile = function (urlData, filename) {
  if (typeof urlData !== 'string') {
    return;
  }
  const arr = urlData.split(',');
  const type = arr[0].match(/:(.*?);/)[1];
  const fileExt = type.split('/')[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${filename}.${fileExt}`, {
    type: type
  });
};

export const fileMD5 = (file) => (new Promise((resolve) => {
  const bmf = new BMF();
  bmf.md5(file, function (err, md5) {
    if (err) {
      return resolve('');
    }
    resolve(md5);
  });
}));

export const getFileSize = (fileSize) => {
  // 1 G = 1073741824 B
  // 1 MB = 1048576 B
  // 1 KB = 1024 B
  let result = '';
  if (fileSize >= 1073741824) {
    // B => GB
    result = fileSize % 1073741824 === 0 ? fileSize / 1073741824 + 'G' : Math.trunc(fileSize / 1073741824) + 'G';
  } else if (fileSize >= 1048576) {
    // B => MB
    result = fileSize % 1048576 === 0 ? fileSize / 1048576 + 'MB' : Math.trunc(fileSize / 1048576) + 'MB';
  } else if (fileSize >= 1024) {
    // B => KB
    result = fileSize % 1024 === 0 ? fileSize / 1024 + 'KB' : Math.trunc(fileSize / 1024) + 'KB';
  } else {
    result = fileSize + 'B';
  }
  return result;
}