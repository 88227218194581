import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout/layout.vue'
import store from '../store'
import { loadUserData } from '../utils/userdata'

loadUserData();
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: layout,
    children: [
      {
        path: '/',
        name: 'home',
        meta: { keepAlive: true },
        component: () =>
          import('@/views/home/home.vue')
      },
      {
        path: '/contacts',
        name: 'contacts',
        meta: { keepAlive: true },
        component: () =>
          import('@/views/user_contacts/user_contacts.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: { noAuth: true },
    component: () =>
      import('@/views/login/login.vue')
  },
  {
    path: '*',
    name: '404',
    meta: { noAuth: true },
    component: () =>
      import('@/views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.meta.noAuth) {
    if (!store.state.user.authToken || !store.state.user.userid) {
      return next({ name: 'login' });
    }
  }
  next();
});

export default router