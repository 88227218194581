import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ElementLocale from 'element-ui/lib/locale';
import cnLocale from './language/ch';
import enLocale from './language/en';
import jpLocale from './language/jp';
import elEnLocale from 'element-ui/lib/locale/lang/en';
import elCnLocale from 'element-ui/lib/locale/lang/zh-CN';
import elJpLocale from 'element-ui/lib/locale/lang/ja';

Vue.use(VueI18n);

let currentLang = localStorage.getItem('language');

if (!currentLang) {
  currentLang = 'en';
  localStorage.setItem('language', currentLang);
}

const i18n = new VueI18n({
  locale: currentLang,
  messages: {
    ch: {
      ...cnLocale,
      ...elCnLocale,
    },
    en: {
      ...enLocale,
      ...elEnLocale,
    },
    jp: {
      ...jpLocale,
      ...elJpLocale,
    },
  }
});

ElementLocale.i18n((key, value) => i18n.t(key, value));

export default i18n