import { connection } from "./jsstore_con";
import BaseService from './base_service';
import { MessageType, MessageChatType } from "@/config/messageDefined";
import i18n from '@/i18n';
import store from '@/store';
import { COMPLETE_FILE_URI } from "@/config/endpoints";

export default class ConversationService extends BaseService {

  constructor() {
    super();
    this.tableName = "Conversation";
  }

  async getConversations() {
    return await connection.select({
      from: this.tableName,
      order: [
        {
          by: 'is_top',
          type: 'desc' //supprted sort type is - asc,desc
        },
        {
          by: 'last_ts',
          type: 'desc' //supprted sort type is - asc,desc
        }
      ]
    });
  }

  async updateConversationsWithMsg(data) {
    const cons = await connection.select({
      from: this.tableName,
      where: {
        con_id: data.con_id,
      }
    });
    let msgContent;
    if (data.m_type === MessageType.Text) {
      msgContent = data.body.content;
    } else if (data.m_type === MessageType.Image) {
      msgContent = i18n.t('message.consImageShowName');
    } else if (data.m_type === MessageType.Video) {
      msgContent = i18n.t('message.consVideoShowName');
    } else if (data.m_type === MessageType.Audio) {
      msgContent = i18n.t('message.consAudioShowName');
    } else if (data.m_type === MessageType.File) {
      msgContent = i18n.t('message.consFileShowName');
    } else if (data.m_type === MessageType.Emj) {
      msgContent = i18n.t('message.consBigEmojiShowName');
    } else {
      msgContent = i18n.t('message.consNotSupportShowName');
    }
    if (cons.length > 0) {
      await connection.update({
        in: this.tableName,
        set: {
          last_msg: msgContent,
          last_ts: data.s_time,
        },
        where: {
          con_id: data.con_id,
        }
      });
    } else {
      let con_name = i18n.t('message.unknownUser'), avatar = '', is_top = 0;
      if (data.c_type === MessageChatType.Singel) {
        const contactInfo = await store.dispatch('contacts/getContactByUserid', data.con_id);
        if (contactInfo) {
          con_name = contactInfo.specialNickName || contactInfo.nickName;
          avatar = contactInfo.icon;
          is_top = contactInfo.isTop;
        } else {
          if (data.s_info.nickName) {
            con_name = data.s_info.nickName;
            avatar = COMPLETE_FILE_URI(data.s_info.icon);
          }
        }
      } else if (data.c_type === MessageChatType.Group) {
        const groupInfo = await store.dispatch('contacts/getGroupInfoByGroupid', data.con_id);
        if (groupInfo) {
          con_name = groupInfo.groupName;
          avatar = COMPLETE_FILE_URI(groupInfo.groupIcon);
          is_top = groupInfo.isTop;
        }
      }
      await this.add([{
        con_id: data.con_id,
        con_name,
        last_msg: msgContent,
        last_ts: data.s_time,
        avatar,
        msg_badge: 0,
        chat_type: data.c_type,
        is_top,
      }]);
    }
  }

  async insertConversationIfNotExists(info) {
    const con_id = info.userId || info.groupId;
    const cons = await connection.select({
      from: this.tableName,
      where: { con_id }
    });
    if (cons.length > 0) return false;

    let data = {
      con_id,
      last_msg: '',
      last_ts: Date.now(),
      msg_badge: 0,
      is_top: 0,
    };
    if (info.userId) {
      data.con_name = info.specialNickName || info.nickName;
      data.avatar = info.icon;
      data.chat_type = MessageChatType.Singel;
    } else {
      data.con_name = info.groupName;
      data.avatar = info.groupIcon;
      data.chat_type = MessageChatType.Group;
    }

    await this.add([data]);
    return true;
  }

  async updateConvUserInfoById(con_id) {
    const contactInfo = await store.dispatch('contacts/getContactByUserid', con_id);
    if (contactInfo) {
      await connection.update({
        in: this.tableName,
        set: {
          con_name: contactInfo.specialNickName || contactInfo.nickName,
          avatar: contactInfo.icon,
        },
        where: {
          con_id,
        }
      });
    }
  }

  async updateConvGroupInfoById(con_id) {
    const groupInfo = await store.dispatch('contacts/getGroupInfoByGroupid', con_id);
    if (groupInfo) {
      await connection.update({
        in: this.tableName,
        set: {
          con_name: groupInfo.groupName,
          avatar: groupInfo.groupIcon,
        },
        where: { con_id }
      });
    }
  }

}