import {
  getFriendsAndGroup,
  getFriendAcceptStatus
} from "@/api/api";
import ContactsService from '@/service/contacts_service';
import GroupService from "@/service/group_service";
import {
  COMPLETE_FILE_URI
} from "@/config/endpoints";
import Pinyin from 'js-pinyin'

const contactsService = new ContactsService();
const groupService = new GroupService();

export default {
  async fetchContactsService({ dispatch }) {
    let friends = [], groups = [], done = false;
    for (let i = 0; i <= 3; i++) {
      try {
        const res = await getFriendsAndGroup();
        if (res && res.code === 200) {
          friends = res.data.friendsList.map((data) => {
            const _data = {};
            _data.userId = data.userId;
            _data.hiChatId = data.hiChatId;
            _data.nickName = data.nickName;
            _data.nickNameFirstLetter = Pinyin.getFullChars(data.specialNickName || data.nickName).charAt(0);
            _data.specialNickName = data.specialNickName;
            _data.icon = COMPLETE_FILE_URI(data.icon);
            _data.isReceiveMsg = data.isReceiveMsg;
            _data.talkType = data.talkType;
            _data.isStar = data.isStar;
            _data.isTop = data.isTop;
            _data.status = data.status;
            _data.isVIP = data.isVIP;
            _data.model = data.model;
            _data.recommendationMyCard = data.recommendationMyCard;
            _data.tag = data.tag;
            _data.description = data.description;
            return _data;
          });
          groups = res.data.groupList.map(data => {
            data.receiveMsg = Number(data.receiveMsg);
            data.isTop = Number(data.isTop);
            data.createDate = Number(data.createDate);
            return data;
          });
          done = true;
        }
      } catch { }
      if (done) break;
    }

    if (friends.length) {
      await contactsService.clearAndAdd(friends);
    }

    if (groups.length) {
      await groupService.clearAndAdd(groups);
    }

    await dispatch('contactsFromDb');
  },

  async contactsFromDb({ dispatch , commit }) {
    const datas = await contactsService.getContacts();
    commit('setFriendsAndGroup', datas);

    await dispatch('groupContactsFromDb');
  },

  async requestContactsFromDb({ commit }) {
    const datas = await contactsService.getContacts();
    commit('setFriendsAndGroup', datas);
  },

  async groupContactsFromDb({ commit }){
    const groupdatas = await groupService.getGroups();
    commit('setGroup', groupdatas);
  },

  // 获取好友申请列表
  async FriendRequestFromDb({ commit }){
    const groupdatas = await getFriendAcceptStatus();
    commit('setFriendRequest', groupdatas);
  },

  async groupDataAdd({ } , data){
    return await groupService.add([data]);
  },

  async getContactByUserid({ }, userid) {
    return await contactsService.getContactByUserid(userid);
  },

  async getGroupInfoByGroupid({ }, groupid) {
    return await groupService.getByGroupId(groupid);
  },

}