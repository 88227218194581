import store from '@/store';
import initJsStore from './initJsStore';

export const loadUserData = () => {
  const userdata = localStorage.getItem("__U");
  if (userdata) {
    let _userdata;
    try {
      _userdata = JSON.parse(userdata);
    } catch { }
    if (_userdata) {
      store.commit("user/initUserAuthData", _userdata);
      if (store.state.user.userid) {
        initJsStore(store.state.user.userid);
      }
    }
  }
}

export const saveUserData = data => {
  store.commit("user/initUserAuthData", data);
  localStorage.setItem("__U", JSON.stringify(data));
}