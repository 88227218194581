import { connection } from "./jsstore_con";
import BaseService from './base_service';

export default class ContactsService extends BaseService {

  constructor() {
    super();
    this.tableName = "Contacts";
  }

  async getContacts() {
    const datas = await connection.select({
      from: this.tableName,
      order: [{
        by: 'nickNameFirstLetter',
        type: 'asc'
      }, {
        by: 'isStar',
        type: 'desc',
      }]

    });
    return datas
  }

  async getContactByUserid(userid) {
    const res = await connection.select({
      from: this.tableName,
      where: {
        userId: userid,
      }
    });
    if (res.length > 0) return res[0];
    return null;
  }

}