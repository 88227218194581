import { logout as logoutApi } from '@/api/api';

export default {
  async logout({ state, commit }) {
    let success = false;
    try {
      const res = await logoutApi();
      if (res && res.code === 200) {
        commit('clearSession');
        success = true;
      }
    } catch { }
    return success;
  },
}