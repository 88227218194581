import actions from "./actions";

export default {
  namespaced: true,
  state: {
    userid: '',
    authToken: '',
    deviceid: '',
    pubKey: '',
    prvKey: '',
    serverKey: '',
    serverKeyVersion: 0,
    userinfo: {
      countryCode: "",
      cellphone: '',
      hiChatId: "",
      email: "",
      nickName: "",
      icon: "",
      isVIP: 0,
      startDate: 0,
      expirationDate: 0,
    },
  },
  mutations: {
    initDeviceid(state, deviceid) {
      state.deviceid = deviceid;
    },
    initKeypair(state, pair) {
      state.pubKey = pair.public;
      state.prvKey = pair.private;
    },
    initUserAuthData(state, data) {
      state.userid = data.userId;
      state.authToken = data.token;
      state.userinfo.countryCode = data.countryCode;
      state.userinfo.cellphone = data.cellphone;
      state.userinfo.hiChatId = data.hiChatId;
      state.userinfo.email = data.email;
      state.userinfo.nickName = data.nickName;
      state.userinfo.icon = data.icon;
      state.userinfo.isVIP = data.isVIP;
      state.userinfo.startDate = data.startDate;
      state.userinfo.expirationDate = data.expirationDate;
    },
    setServerKey(state, data) {
      state.serverKey = data.pubKey;
      state.serverKeyVersion = data.version;
    },
    clearSession(state) {
      state.userid = '';
      state.authToken = '';
      state.userinfo = {
        countryCode: "",
        cellphone: '',
        hiChatId: "",
        email: "",
        nickName: "",
        icon: "",
        isVIP: 0,
        startDate: 0,
        expirationDate: 0,
      };
      localStorage.removeItem('__U');
    },
  },
  actions,
}