import { getSessionList } from "@/api/api";
import { COMPLETE_FILE_URI } from "@/config/endpoints";
import { MessageType , MessageChatType } from "@/config/messageDefined";
import ConversationService from '@/service/conversation_service';
import MessageService from '@/service/message_service';
import ContactsService from '@/service/contacts_service';
import GroupService from '@/service/group_service';
import format from 'date-fns/format';

const conversationService = new ConversationService();
const messageService = new MessageService();
const contactsService = new ContactsService();
const groupService = new GroupService();

export default {
  async fetchConversations({ commit, dispatch, rootState }) {
    let sessionList = [], done = false;
    let messageList = {};
    for (let i = 0; i < 3; i++) {
      try {
        const res = await getSessionList();
        messageList = {};
        if (res && res.code === 200) {
          sessionList = res.data.talkInfo.map((data) => {
            const _data = {};
            _data.avatar = COMPLETE_FILE_URI(data.icon);
            _data.con_id = data.conId;
            _data.con_name = data.conName;
            _data.last_ts = Number(data.time);
            _data.chat_type = data.chatType;
            _data.is_top = data.isTop;
            const messages = [];
            data.messages.forEach((message) => {
              try {
                if (
                  message.c_type !== MessageChatType.Group &&
                  message.c_type !== MessageChatType.Singel
                ) {
                  return;
                }
                message.body = JSON.parse(message.body);
                message.is_read = 1;
                message.m_status = 4;
                message.is_mine = Number(rootState.user.userid === message.s_uid);
                message.s_time = Number(message.s_time);
                if (!message.s_msgId) message.s_msgId = message.c_msgId;
                messages.push(message);
              } catch { }
            });
            messageList[_data.con_id] = messages;
            return _data;
          });
          done = true;
        }
      } catch { }
      if (done) break;
    }
    if (sessionList.length) {
      for (const con of sessionList) {
        try {
          await conversationService.add([con]);
          const messages = messageList[con.con_id];
          let lastMsg, s_info = {},groupinfo=null;
          if (con.chat_type === MessageChatType.Singel) {
            const userinfo = await contactsService.getContactByUserid(con.con_id);
            if (userinfo) {
              s_info = { nickName: userinfo.nickName, icon: userinfo.icon };
            }
          }else if (con.chat_type === MessageChatType.Group) {
            groupinfo = await groupService.getByGroupId(con.con_id);
          }
          

          
          for (const message of messages) {
            if(MessageType.system == message.m_type || MessageType.Sysnoti == message.m_type){
              continue;
            }
            try {
              if (!message.is_mine) {
                if (con.chat_type === MessageChatType.Group &&groupinfo) {
                  s_info = {nickName: "", icon: ""};
                  for (const item of groupinfo.members) {
                    if(item.userId == message.s_uid){
                      s_info = { nickName: item.nickName, icon: item.icon };
                    }
                  }
                }
                message.s_info = s_info;
              }
              await messageService.add([message]);
              lastMsg = message;
            } catch { }
          }
          if (lastMsg) {
            await conversationService.updateConversationsWithMsg(lastMsg);
          }
        } catch { }
      }
    }
    await dispatch('conversationFromDb');
  },

  async conversationFromDb({ commit }) {
    const datas = await conversationService.getConversations();
    const finalData = [];
    let totalUnread = 0;
    const myDate = new Date();
    for (const data of datas) {
      data.timeStr = format(data.last_ts, 'HH:mm');
      let time_month = format(data.last_ts, 'MM-dd');
      const m = (myDate.getMonth() + 1) < 10 ? "0" + (myDate.getMonth() + 1) : (myDate.getMonth() + 1);
      const d = (myDate.getDate()) < 10 ? "0" + (myDate.getDate()) : (myDate.getDate());
      if (time_month == `${m}-${d}`) {
        data.time_month = "";
      } else {
        data.time_month = format(data.last_ts, 'MM-dd');
      }
      data.msg_badge = await messageService.getUnreadCountByConid(data.con_id);
      totalUnread += data.msg_badge;
      finalData.push(data);
    }
    commit('setCons', finalData);
    commit('setTotalUnread', totalUnread);
  },

  async markConversationRead({ dispatch }, con_id) {
    await messageService.markRead(con_id);
    await dispatch('conversationFromDb');
  },

  async getMessageByConid({ }, con_id) {
    return await messageService.getMsgByConid(con_id);
  },

  async storeMessage({ dispatch }, data) {
    await messageService.add([data]);
    await conversationService.updateConversationsWithMsg(data);
    await dispatch('conversationFromDb');
  },

  async updateMessageStatus({ }, data) {
    return await messageService.updateMessageStatus(data);
  },

  async startChat({ dispatch }, contact) {
    const res = await conversationService.insertConversationIfNotExists(contact);
    if (res) {
      await dispatch('conversationFromDb');
    }
  },

  async updateConversationsInfo({ dispatch }) {
    const datas = await conversationService.getConversations();
    for (const data of datas) {
      if (data.chat_type === MessageChatType.Singel) {
        await conversationService.updateConvUserInfoById(data.con_id);
      } else if (data.chat_type === MessageChatType.Group) {
        await conversationService.updateConvGroupInfoById(data.con_id);
      }
    }
    dispatch('conversationFromDb');
  },
}