import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import message from './modules/message'
import contacts from './modules/contacts'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading:false,
    },
    modules: {
        user,
        message,
        contacts,
    }
})