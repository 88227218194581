import { connection } from "./jsstore_con";

export default class BaseService {

  add(datas) {
    return connection.insert({
      into: this.tableName,
      values: datas,
      return: true
    })
  }

  async clearAndAdd(datas) {
    await connection.clear(this.tableName);
    return await connection.insert({
      into: this.tableName,
      values: datas,
      return: true
    });
  }

}