import { DATA_TYPE } from "jsstore";

export default {
  name: 'Group',
  columns: {
    id: {
      primaryKey: true,
      autoIncrement: true
    },
    groupId: {
      notNull: true,
      dataType: DATA_TYPE.String,
      unique: true,
    },
    groupName: {
      dataType: DATA_TYPE.String,
      default: '',
    },
    groupIcon: {
      dataType: DATA_TYPE.String,
      default: '',
    },
    createDate: {
      dataType: DATA_TYPE.Number,
      default: 0,
    },
    creater: {
      notNull: true,
      dataType: DATA_TYPE.String,
    },
    inviteValidate: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
    announcement: {
      dataType: DATA_TYPE.String,
      default: '',
    },
    haveName: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
    receiveMsg: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
    isTop: {
      notNull: true,
      dataType: DATA_TYPE.Number,
    },
    members: {
      dataType: DATA_TYPE.Array,
      default: [],
    },
  }
}