<template>
  <div
    id="app"
    v-loading="$store.state.api_loading"
    element-loading-background="rgba(0, 0, 0, 0.6)"
  >
    <router-view></router-view>
  </div>
</template>

<script>
import initJsStore from "./utils/initJsStore";
import { connection } from "@/service/jsstore_con";
export default {
  computed: {
    userid() {
      return this.$store.state.user.userid;
    },
  },
  watch: {
    async userid() {
      if (this.userid) {
        await initJsStore();
        this.$store.dispatch("contacts/fetchContactsService").then(() => {
          this.$store.dispatch("message/updateConversationsInfo");
        });
      } else {
        await connection.dropDb();
      }
    },
  },
};
</script>

<style>
* {
  /* font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif; */
  font-family: PingFangSC-Regular, PingFang SC;
  margin: 0;
  padding: 0;
}
</style>
