<template>
  <el-container
    v-loading="$store.state.loading"
    element-loading-background="rgba(0, 0, 0, 0.6)"
  >
    <Sidebar />

    <el-container>
      <!-- <el-header height="80px">
          <Header/>
        </el-header> -->
      <el-main>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  components: {
    // Header: () => import("./components/header.vue"),
    Sidebar: () => import("./components/sidebar.vue"),
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  destroyed() {},
  watch: {},
};
</script>

<style scoped>
/* .el-header {
  padding: 0 !important;
} */
.el-main {
  /* height: calc(100vh - 80px); */
  /* height: 100vh; */
  min-height: 300px;
  overflow: hidden;
  background: #f5f6f7;
  color: #333333;
  padding: 0;
}
/* .el-main::-webkit-scrollbar {
  width: 5px;
}
.el-main::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #F1C788;
} */

/* 手机端 */
/* @media screen and (max-width: 850px) {
  .el-main{
    overflow-y: auto;
    padding: 20px;
  }
} */
</style>
