import debug from 'debug';

if (process.env.NODE_ENV === 'production') {
  localStorage.removeItem('debug');
} else {
  localStorage.debug = 'HICHAT:*';
}

export const loginfo = debug('HICHAT:info');
loginfo.log = console.log.bind(console);

export const logwarn = debug('HICHAT:warn');
logwarn.log = console.warn.bind(console);

export const logerror = debug('HICHAT:error');
logerror.log = console.error.bind(console);
