import axios from 'axios';
import store from '@/store';
import i18n from '@/i18n';
// import router from '@/router';

const client = axios.create({
  responseType: 'json',
});

client.interceptors.request.use(config => {
  if (store.state.user.authToken) {
    config.headers.token = store.state.user.authToken;
  }
  if (store.state.user.userid) {
    config.headers.userid = store.state.user.userid;
  }
  config.headers.language = i18n.locale;
  config.headers['device-id'] = store.state.user.deviceid;
  return config;
});

client.interceptors.response.use(response => {
  if (response.status === 200) {
    const data = response.data;
    if (data.code === 9400) {
      store.commit('user/clearSession');
      router.push({ name: 'login' });
    }
    return data;
  }

  return response;
});

export default client;