import actions from "./actions";

export default {
    namespaced: true,
    state: {
        FriendsList: [],
        GroupList: [],
        FriendRequestList: []
    },
    mutations: {
        setFriendsAndGroup(state, datas) {
            state.FriendsList = datas;
        },
        setGroup(state, datas) {
            state.GroupList = datas;
        },
        setFriendRequest(state, datas) {
            state.FriendRequestList = datas;
        },
    },
    actions,
}