import { connection } from "./jsstore_con";
import BaseService from './base_service';
import format from 'date-fns/format';

export default class MessageService extends BaseService {

  constructor() {
    super();
    this.tableName = "Message";
  }

  async getMsgByConid(con_id) {
    const data = await connection.select({
      from: this.tableName,
      where: { con_id },
      order: [
        {
          by: 's_time',
          type: 'asc'
        }
      ]
    });

    const _cahce = {};
    return data.map(_data => {
      const hhmm = format(_data.s_time, 'HH:mm');
      const date = format(_data.s_time, 'yyyy-MM-dd');
      if (!_cahce[date]) {
        _data.topTimeShow = true;
        _data.topTime = date;
        _cahce[date] = true;
      }
      _data.timeString = hhmm;
      return _data;
    });
  }

  updateMessageStatus({ c_msgId, s_msgId, s_time, m_status }) {
    const set = { m_status };
    if (s_msgId) set.s_msgId = s_msgId;
    if (s_time) set.s_time = s_time;
    return connection.update({
      in: this.tableName,
      set: set,
      where: {
        c_msgId: c_msgId,
      }
    });
  }

  async getUnreadCountByConid(con_id) {
    const count = await connection.count({
      from: this.tableName,
      where: {
        con_id: con_id,
        is_mine: 0,
        is_read: 0,
      }
    });
    return count;
  }

  async markRead(con_id) {
    await connection.update({
      in: this.tableName,
      set: {
        is_read: 1,
      },
      where: {
        con_id,
        is_mine: 0,
      }
    });
  }

}