import actions from "./actions";

export default {
  namespaced: true,
  state: {
    conversations: [],
    totalUnread: 0,
  },
  mutations: {
    setCons(state, datas) {
      state.conversations = datas;
    },
    setTotalUnread(state, num) {
      state.totalUnread = num;
    },
  },
  actions,
}