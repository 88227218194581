import request from '@/utils/httpClient';
import { CHECK_LOGIN, GEN_RSA_KEY, GET_FRIENDS_AND_GROUP, GET_GROUP_MEMBERS_INFO, GET_LOGIN_UUID, GET_SCAN, GET_SERVER_KEY, GET_SESSION_LIST, LOGOUT , getFSAuth_url,createGroup_url,searchFriend_url,addFriendRequest_url,getFriendAcceptStatus_url,getAddFriendResponseHandle_url,getGroupMembersInfo_url } from './urls';
import { md5 } from '@/utils/utils';
import store from '@/store';

export const getLoginUUID = async () => {
  const timestamp = Date.now();
  const params = {
    time: timestamp,
    secretKey: md5("HICHAT" + timestamp + "PhwdYyfWXxDznPPM" + store.state.user.deviceid),
  };

  return await request({
    url: GET_LOGIN_UUID,
    method: 'post',
    data: params,
  });
}

export const getScan = async uuid => {
  return await request({
    url: GET_SCAN,
    method: 'post',
    data: { uuid },
  });
}

export const checkLogin = async uuid => {
  return await request({
    url: CHECK_LOGIN,
    method: 'post',
    data: { uuid },
  });
}

export const syncServerKey = v => request({
  url: GET_SERVER_KEY,
  method: 'post',
  data: { version: v },
});

export const getSessionList = data => request({
  url: GET_SESSION_LIST,
  method: 'post',
  data,
});

export const logout = data => request({
  url: LOGOUT,
  method: 'post',
  data,
});

export const getFriendsAndGroup = data => request({
  url: GET_FRIENDS_AND_GROUP,
  method: 'post',
  data,
});

export const getGroupMembersInfo = data => request({
  url: GET_GROUP_MEMBERS_INFO,
  method: 'post',
  data,
});

export const genRsaKey = () => request({
  url: GEN_RSA_KEY,
  method: 'post',
});

// 获取文件系统认证token
export const getFSAuth = data => request({
  url: getFSAuth_url,
  method: 'post',
  data
})

// 创建群组
export const createGroup = data => request({
  url: createGroup_url,
  method: 'post',
  data
})

// 搜索好友
export const searchFriend = data => request({
  url: searchFriend_url,
  method: 'post',
  data
})

// 添加好友
export const addFriendRequest = data => request({
  url: addFriendRequest_url,
  method: 'post',
  data
})

// 获取好友请求列表
export const getFriendAcceptStatus = data => request({
  url: getFriendAcceptStatus_url,
  method: 'post',
  data
})

// 处理好友请求
export const getAddFriendResponseHandle = data => request({
  url: getAddFriendResponseHandle_url,
  method: 'post',
  data
})

// 获取群组成员列表
export const getGroupMembersList = data => request({
  url: getGroupMembersInfo_url,
  method: 'post',
  data
})
