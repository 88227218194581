import { connection } from "./jsstore_con";
import BaseService from './base_service';

export default class GroupService extends BaseService {

  constructor() {
    super();
    this.tableName = "Group";
  }

  async getGroups() {
    const datas = await connection.select({
      from: this.tableName,
      order: [{
        by: 'isTop',
        type: 'desc',
      }]

    });
    return datas
  }

  async getByGroupId(groupId) {
    const res = await connection.select({
      from: this.tableName,
      where: { groupId }
    });
    if (res.length > 0) return res[0];
    return null;
  }

}