module.exports = {//例子 this.$i18n.t('main.submit_audit')  {{$t('main.status_name')}}

  main: {
    'text1':                             '欢迎来到HiChat',
    'text2':                             '让你有不同的通信APP的体验',
    'text3':                             '对隐私的意外关注，结合您期望的所有功能',
    'text4':                             '扫描登入',
    'text5':                             '该网页需要与您的手机登录一起使用',
    'text6':                             '您确定要退出吗',
    'text7':                             '取消',
    'text8':                             '确认',
    'text9':                             '请输入关键字',
    'text10':                            '输入消息',
    'text11':                            '备注',
    'text12':                            '来源',
    'text13':                            '来自群聊',
    'text14':                            '发送',
    'text15':                            '好友',
    'text16':                            '登入中',
    'text17':                            '扫描成功',
    'text18':                            '使用手机确认登录',
    'text20':                            '浏览器不支持播放此文件',
    'text21':                            '当前版本不支持此类消息',
    'group_creation_error':              '群组创建失败',
    'successfully_added':                '添加成功',
    'consent':                           '通过',
    'refuse':                            '拒绝',
    'not_find':                          '找不到文件，请再试一次',
    'MD5_failed':                        '文件MD5计算失败，请重试',
    'least_choice':                      '请最少选择两个人',
    'person':                            '人',
    'Group_announcement':                '群公告',
    'Group_member':                      '群成员',
    'New_friend':                        '新的朋友',
    'group_chat':                        '群聊',
    'cancel':                            '取消',
    'add_friends':                       "添加好友",
    'creation':                          '创建',
    'Start_group_chat':                  '发起群聊',
    'unselected':                        '未选择联系人',
    'selected':                          '已选择',
    'Contact':                           '个联系人',
    'View':                               '查看',
    "enter_Hichat":                       "请输入Hichat号",


  },
  message: {
    consImageShowName: '[图片]',
    consVideoShowName: '[视频]',
    consAudioShowName: '[语音]',
    consFileShowName: '[文件]',
    consBigEmojiShowName: '[动画表情]',
    consNotSupportShowName: '[当前版本不支持该消息类型]',
    unknownUser: '[未知用户]',
  },
  contacts: {
    starFriends: '星标朋友',
  },
}
